<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo-symbol-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--180 bg_image bg_image--1"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="blog-single-page-title text-center pt--50">
              <h2 class="heading-title theme-gradient" v-if="teacher">
                {{ teacher.name }} {{ teacher.surname }}
              </h2>
              <ul class="blog-meta d-flex justify-center align-center" v-if="teacher">
                <li class="d-flex" v-if="teacher.online">
                  <span v-html="iconSvg('headphones')"></span>Lekcje online
                </li>
                <li class="d-flex" v-if="teacher.mobile">
                  <span v-html="iconSvg('phone')"></span>{{ teacher.mobile }}
                </li>
                <li class="d-flex" v-if="teacher.entitlement">
                    <span v-html="iconSvg('info')"></span>Uprawnienia {{ teacher.entitlement }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--110 pb--70 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="6">
              <div class="inner-wrapper">
                  <div class="thumbnail" v-if="teacher">
                      <img :src="teacher.photo" alt="" />
                  </div>
              </div>
          </v-col>
          <v-col cols="6">
              <p class="inner" v-if="teacher" v-html="teacher.description">
                {{ teacher.description }}
              </p>
              <div class="inner pt--20" v-if="teacher">
                  <table class="table table-bordered">
                      <tbody>
                          <tr v-if="teacher.mobile">
                              <td class="pr--20">
                                  <p>Telefon</p>
                              </td>
                              <td class="pt--20 pb--20">
                                  <a class="btn-transparent rn-btn-dark">
                                      <span class="text">{{ teacher.mobile }}</span>
                                  </a>
                              </td>
                          </tr>
                          <tr v-if="teacher.email">
                              <td class="pr--20">
                                  <p>E-mail</p>
                              </td>
                              <td>
                                  <a :href="mailTo(teacher.email)" class="btn-transparent rn-btn-dark">
                                      <span class="text">{{ teacher.email }}</span>
                                  </a>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import Footer from "../components/footer/Footer";
  import axios from 'axios';
  import feather from "feather-icons";

  export default {
    components: {
      Header,
      Footer
    },
      data() {
          return {
              teacher: null
          };
      },
      methods: {
          mailTo(mail) {
              return 'mailto:' + mail;
          },
          iconSvg(icon) {
              console.log('ICONS', feather.icons);
              return feather.icons[icon].toSvg();
          },
      },
      mounted() {
          // const slug = 'warsztat-z-goranem-morkebergiem-w-lublinie';
          const path = this.$route.path.split('/') ;
          const slug = path[2];
          console.log("SLUG", slug);

          axios({
              url: process.env.VUE_APP_API_ENDPOINT,
              method: 'post',
              data: {
                  query: `
                    query getTeacher {
                        teacher(slug: "` + slug + `") {
                            name,
                            surname,
                            email,
                            mobile,
                            description,
                            entitlement,
                            online,
                            voivodeship,
                            photo
                        }
                    }
                `
              }
          })
          .then(response => {
              console.log('TEACHER', response.data.data.teacher);
              if (response.data.data.teacher) {
                  this.teacher = response.data.data.teacher;
              }
          })
      }
  };
</script>
